import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaMedal} from "react-icons/fa";


function Footer () {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Diamond
    </Tooltip>
  );
  
  const renderTooltip2 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
  Gold  </Tooltip>
  );
  
  const renderTooltip3 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Silver
    </Tooltip>
  );
  
  const renderTooltip4 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Bronze
    </Tooltip>
  );
  
  const renderTooltip5 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      No Rank
    </Tooltip>
  );

    return (
      <div>
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between py-2">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © <a href="https://infinity.cayor.tech/" target="_blank" rel="noopener noreferrer">infinity Staffing soluitons </a>2022</span>
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip4}
              >
                <button
                  type="button"
                  className="btn btn-gradient-dark btn-rounded btn-icon"
                >
                                   <FaMedal 
                 style={{color:"#CD7F32", size:'100px'}}
              
                />
                 
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip3}
              >
                <button
                  type="button"
                  className="btn btn-gradient-dark btn-rounded btn-icon"
                >
                  <FaMedal 
                 style={{color:"#C0C0C0", size:'100px'}}
              
                />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip2}
              >
                <button
                  type="button"
                  className="btn btn-gradient-dark btn-rounded btn-icon"
                >
                 <FaMedal 
                 style={{color:"#F5E81B", size:'100px'}}
              
                />
                
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <button
                  type="button"
                  className="btn btn-gradient-dark btn-rounded btn-icon"
                >
                   <FaMedal 
                 style={{color:"#B9F2FF", size:'100px'}}
              
                />
                </button>
              </OverlayTrigger>
              </div>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Develop By  <a href="https://cayorenterprises.com/" target="_blank" rel="noopener noreferrer"> Cayor Enterprises </a>   </span>
        </div>
      </footer> 
      </div>
    );
}

export default Footer;