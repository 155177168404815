import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Registration = lazy(() => import('./add-staff/Registration'));
const History = lazy(() => import('./tables/History'));
const Login = lazy(() => import('./user-pages/Login'));
const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));

const BlankPage = lazy(() => import('./empty-pages/BlankPage'));




class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
        
        {/* <Route
  exact
  path="/"
  render={props => (
    <Page {...props} component={Index} title="Index Page" />
  )}
/> */}


          <Route exact path="/addstaff" component={ Registration } />
          
          <Route  path="/dashboard"  component={ Dashboard } />

          <Route path="/history" component={ History } />

          <Route  path="/login"  component={ Login } />
          <Route path="/user-pages/lockscreen" component={ Lockscreen } />
     
          <Route path="/empty-pages/blank-page" component={ BlankPage } />


          <Redirect to="/login" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;